import React, { useState } from 'react';
import Slider from 'react-slick';
import { TestimonialTwoParams } from '../../utils/script';
import { TestimonialTwoThumDots } from '../../utils/script';

const data = [
    {
        name: "Kousalya Lakshmanan",
        details: "I received the best training from Ram & Archana. They respond quickly and clear all your queries. More practical sessions were included and it helped me to understand the theoretical concepts too. I would recommend anyone here for the Basic to Advanced python training 10/10",
        designation: "Data Analyst",
        image: "client-01.png"
    },
    {
        name: "Nirmal Kumar",
        details: "Thank you Blastout solutions for taught me the python course,In this course i have learnt new concepts and techniques which was not taught anywhere else.I like the way they taught the concepts its quite unique and  they relate the concept with real time examples.",
        designation: "Student",
        image: "client-02.png"
    },
    {
        name: "Kavya",
        details: "I have understood the concepts well in python. Like, the class will be like a complete practical session. Daily, there will be some kind of problem solving section. So, it will be useful for us to understand the concepts.",
        designation: "Student",
        image: "client-03.png"
    },
    {
        name: "Adityan",
        details: "I have attended AWS Beginner program from Blastout Solution. The concepts were well explained and the lab sessions were useful. All questions were answered satisfactorily. I would strongly recommend this program to anyone who wishes to get to the depths of AWS. Thanks.",
        designation: "Software Engineer",
        image: "client-04.png"
    },
]

const TestimonialTwo = ( { classes } ) => {
    const [testimonialNav, setTestimonialNav] = useState();
    const [testimonialThumb, setTestimonialThumb] = useState();

    return (
        <div className={ classes }>
            <Slider className="testimonial-nav-activation"
                asNavFor={ testimonialThumb } 
                ref={ ( slider1 ) => setTestimonialNav( slider1 ) }
                { ...TestimonialTwoParams }
            >
                { data.map( ( item, i ) => (
                    <div className="testimonial-nav-content" key={ i }>
                        <p className="description">“ { item.details }”</p>
                        <div className="client-info">
                            <h6 className="title">{ item.name }</h6>
                            <span className="designation">{ item.designation }</span>
                        </div>
                    </div>
                ) ) }
            </Slider>

            <div className="testimonial-nav-wrapper">
                <Slider className="testimonial-nav-button" 
                    { ...TestimonialTwoThumDots }
                    asNavFor={ testimonialNav }
                    ref={ ( slider2 ) => setTestimonialThumb( slider2 ) }
                >
                    { data.map( ( item, i ) => (
                        <div className="single-thumbnail" key={ i }>
                            <img src={`${process.env.PUBLIC_URL}./images/testimonial/testimonial-02/${item.image}`} alt="Client Thumb" />
                            <div className="loader-container">
                                <div className="circle-loader-wrap">
                                    <div className="left-wrap">
                                        <div className="loader"></div>
                                    </div>
                                    <div className="right-wrap">
                                        <div className="loader"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) ) }
                </Slider>
            </div>
        </div>
    );
}
export default TestimonialTwo;