import React, { useState, useContext } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import SectionTitle from '../sectionTitle/SectionTitle';

const accordionItems = [
    {
        id: 1,
        title: 'How long does it to complete a course?',
        body: 'Time to complete a Certification can vary by subject or how you choose to learn at your own pace. On average, a Professional Certificate can be completed in less than 3 months at 10 hours per week.'
    },
    {
        id: 2,
        title: 'What is the Pre-requisite to take a course?',
        body: 'None! Whether you’re completely new to the field or have had some experience, a Beginner - Level Professional Certification doesn’t require any background knowledge. In an advanced or Intermediate Level, there are pre-requisite.'
    },
    {
        id: 3,
        title: 'Who can access webinars?',
        body: 'All the persons who registered for any courses can attend any of the webinars.'
    },
    {
        id: 4,
        title: 'How long the course material will be available for students?',
        body: 'The course materials will be updated when you complete each modules, you can access the materila based on the term that you opted during purchased.'
    }
]

const CustomToggle = ({ children, eventKey }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton( eventKey );
    const isCurrentEventKey = activeEventKey === eventKey;
    return <button type="button" onClick={decoratedOnClick} aria-expanded={ isCurrentEventKey ? true : false } className="edu-accordion-button">{children}</button>
}

const AboutTwo = () => {
    const [activeId, setActiveId] = useState( '0' );
    function toggleActive( id ) {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    return (
        <div className="edu-accordion-area eduvibe-home-three-accordion accordion-shape-1 edu-section-gap bg-color-white" id='faq'>
            <div className="container eduvibe-animated-shape">
                <div className="row g-5">
                    <div className="col-lg-6 gallery-wrapper">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="thumbnail">
                                    {/* <img className="radius-small w-100" src="/images/about/about-02/accordion-gallery-01.jpg" alt="About Thumb 1" /> */}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mt--35 mt_sm--15">
                                <div className="thumbnail">
                                    <img className="radius-small w-100" src="/images/about/about-02/Blastout SolutionsF A Q.png" alt="About Thumb 2" />
                                </div>
                                <div className="thumbnail mt--15">
                                    <img className="radius-small w-100" src="/images/about/about-02/Blastout Solutions Best.png" alt="About Thumb 3" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <SectionTitle
                            classes = "text-start mb--40"
                            slogan = "Do questions pop-up in your mind?"
                            title = "What Blastout Frequently Answers!"
                        />   
                        <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            className="accordion-style-1"
                            animateOnce={ true }
                        >
                            <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                {
                                    accordionItems.map( ( accordion, i ) => (
                                        <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive(i.toString())} bsPrefix={`edu-accordion-item ${activeId === i.toString() ? 'active-item' : ''}`}>
                                            <div className="edu-accordion-header">
                                                <CustomToggle eventKey={ i.toString() }>{ accordion.title }</CustomToggle>
                                            </div>
                                            <Accordion.Body bsPrefix="edu-accordion-body">{ accordion.body }</Accordion.Body>
                                        </Accordion.Item>
                                    ) )
                                }
                            </Accordion>
                        </ScrollAnimation>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape shape-1">
                        <span className="shape-dot"></span>
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-11-04.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-25-01.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;