import React from 'react';
// import { Link } from 'react-router-dom';

const EventOne = ({ data, bgWhite }) => {
    return (
        <div className={`edu-event event-list radius-small ${bgWhite === 'enable' ? 'bg-white' : '' }`}>
            <div className="inner">
                <div className="thumbnail">
                    {/* {data.id} */}
                        <img src={`${process.env.PUBLIC_URL}/images/event/event-01/${data.image}`} alt="Event Thumb" />
                    
                </div>
                <div className="content">
                    <div className="content-left">
                        <h5 className="title">
                            
                            {data.title}
                            
                            
                        </h5>
                        <ul className="event-meta">
                            <li><i className="icon-calendar-2-line"></i>{data.startDate}</li>
                            <li><i className="icon-time-line"></i>{data.startTime}</li>
                            <li><i className="icon-map-pin-line"></i>{data.location}</li>
                        </ul>
                    </div>
                    <div className="read-more-btn">
                        {/* <button>Book a seat</button> */}
                        {/* <Route exact path={`${process.env.PUBLIC_URL + '/contact-events'}`} element={<ContactUsEvent/>}/> */}
                        
                        {/* <a className="edu-btn" href={process.env.PUBLIC_URL + `/contact-events/${data.Link}`}>{data.image} */}
                        {/* <Link className="edu-btn" to={process.env.PUBLIC_URL + '/contact-events/'}>Book A Seat<i className="icon-arrow-right-line-right"></i></Link> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventOne;