import React , { useState } from 'react';
// import emailjs from 'emailjs-com';
// import { Link } from 'react-router-dom';
// const Result = () => {
//     return (
//         <p className="success-message">Thanks for your query. We will contact with you soon.</p>
//     )
// }

function ContactMeForm( { formStyle } ) {
    // const [result, setResult] = useState( false );

    // const sendEmail = ( e ) => {
    //     e.preventDefault();
    //     emailjs
    //     .sendForm(
    //         'service_bxh6md3', 
    //         'template_1g7v07n', 
    //         e.target, 
    //         'user_8Lx0gfI1ktOoeEN8DTV10dee'
    //     )
    //     .then( ( result ) => {
    //         console.log( result.text );
    //         }, 
    //         ( error ) => {
    //             console.log( error.text );
    //         }
    //     );
    //     e.target.reset();
    //     setResult( true );
    // };

    // setTimeout( () => {
    //     setResult( false );
    // }, 5000);
    const url = "https://teams.microsoft.com/registration/7UIuMwfnhk-ezlWCoXVpCw,6VkzY1NwMkWEJX-cYQIiIw,grzJywpnYUeM7VM7ZLhx4Q,-aOjAxYOUUaEDXBkswXuew,_flAX8S5oUuXQnSgxNuX0Q,_RxiOBlHkkC7wWR6MswcFA?mode=read&tenantId=332e42ed-e707-4f86-9ece-5582a175690b"
    return (
            <div className="col-lg-12">
            <a href={url}>
                <button className="rn-btn edu-btn" type="submit">
                    <span>Reserve your seat</span><i className="icon-arrow-right-line-right"></i>
                </button>
                </a>
            </div>
    )
}
export default ContactMeForm;