import React from 'react';
import { Link } from 'react-router-dom';
import ScrollTopButton from './ScrollTopButton';
import { HashLink } from 'react-router-hash-link';

const FooterOne = () => {
    return (
        <>
            <footer className="eduvibe-footer-one edu-footer footer-style-default">
                <div className="footer-top">
                    <div className="container eduvibe-animated-shape">
                        <div className="row g-5">
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="edu-footer-widget">
                                    <div className="logo">
                                        <Link to={process.env.PUBLIC_URL + "/"}>
                                            <img className="logo-light" src="/images/logo/logo-white.png" alt="Footer Logo" />
                                        </Link>
                                    </div>
                                    {/* <p className="description">It is a long established fact that a reader will be distracted by the readable content of a */}
                                        {/* page when looking at its layout. The point of using Lorem Ipsum.</p> */}
                                        <margin>
                                    <ul className="social-share">
                                        <li><a href="https://www.facebook.com/522686094542680/posts/2717043851773549/"><i className="icon-Fb"></i></a></li>
                                        <li><a href="https://www.linkedin.com/in/blastout-solutions-883864241/"><i className="icon-linkedin"></i></a></li>
                                        <li><a href="https://pin.it/5pdATPu"><i className="icon-Pinterest"></i></a></li>
                                        <li><a href="https://twitter.com/BlastoutSoluti1"><i className="icon-Twitter"></i></a></li>
                                    </ul>
                                    </margin>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget explore-widget">
                                    <h5 className="widget-title">Explore</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            
                                            <li><HashLink to="#faq" smooth><i className="icon-Double-arrow"></i>FAQ Question</HashLink></li>
                                            {/* <li><Link to="/about-us-1"><i className="icon-Double-arrow"></i>About Us</Link></li> */}
                                            <li> <HashLink to ="#events" smooth><i className="icon-Double-arrow"></i>Upcoming Events</HashLink></li>
                                            <li><HashLink to="#testimonials" smooth><i className="icon-Double-arrow"></i>Testimonial</HashLink></li>
                                            {/* <li><HashLink to="#blog" smooth><i className="icon-Double-arrow"></i>Blog & News</HashLink></li> */}
                                            <li><Link to="/course-1"><i className="icon-Double-arrow"></i>Popular Courses</Link></li>
                                            <li><Link to="/contact-us"><i className="icon-Double-arrow"></i>Contact Us</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget quick-link-widget">
                                    <h5 className="widget-title">Useful Links</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            {/* <li><Link to="/course-1"><i className="icon-Double-arrow"></i>Popular Courses</Link></li> */}
                                            {/* <li><Link to="/contact-us"><i className="icon-Double-arrow"></i>Contact Us</Link></li> */}
                                            {/* <li><Link to="/pricing"><i className="icon-Double-arrow"></i>Pricing Plan</Link></li> */}
                                            {/* <li><Link to="/instructor-details/Kumar"><i className="icon-Double-arrow"></i>Instructor Profile</Link></li> */}
                                            {/* <li><Link to="/purchase-guide"><i className="icon-Double-arrow"></i>Purchase Guide</Link></li> */}
                                            {/* <li><Link to="/event-details/1"><i className="icon-Double-arrow"></i>Event Details</Link></li> */}
                                            <li><a href="https://merchant.razorpay.com/policy/KUobfdMdFNxh7o/privacy"><i className="icon-Double-arrow"></i>Privacy Policy</a></li>
                                            <li><a href="https://merchant.razorpay.com/policy/KUobfdMdFNxh7o/terms"><i className="icon-Double-arrow"></i>Terms & Conditions</a></li>
                                            <li><a href="https://merchant.razorpay.com/policy/KUobfdMdFNxh7o/refund"><i className="icon-Double-arrow"></i>Refund Policy</a></li>
                                            <li><a href="https://merchant.razorpay.com/policy/KUobfdMdFNxh7o/shipping"><i className="icon-Double-arrow"></i>Shipping Policy</a></li>
                                            <li><a href="https://merchant.razorpay.com/policy/KUobfdMdFNxh7o/contact_us"><i className="icon-Double-arrow"></i>Register Office</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget">
                                    <h5 className="widget-title">Contact Info</h5>
                                    <div className="inner">
                                        <div className="widget-information">
                                            <ul className="information-list">
                                                <li><i className="icon-map-pin-line"></i> Operational Office : No 2, Thiruvandhipuram Main road, Opposite to Pakshi Mandapam, Koothapakkam, Cuddalore-02.</li>
                                                
                                                <li><i className="icon-phone-fill"></i><a href="tel: + 91 70105 37325">+ 91 70105 37325</a></li>
                                                <li><i className="icon-phone-fill"></i><a href="tel: + 91 82208 88276">+ 91 82208 88276</a></li>
                                                <li><i className="icon-mail-line-2"></i><a target="_" href="mailto:bos@blastoutsolutions.com">bos@blastoutsolutions.com</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-21-01.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-35.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area copyright-default">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <p>© 2022 Blastout Solutions. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTopButton />
        </>
    )
}

export default FooterOne;