import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerOne from '../../components/banner/BannerOne';
import VideoSectionTwo from '../../components/video-section/VideoSectionTwo';
import HomeOneCourses from '../../components/home-one/HomeOneCourses';
import HomeTwoEvents from '../../components/home-two/HomeTwoEvents';
import AboutTwo from '../../components/about/AboutTwo';
import TestimonialSectionOne from '../../components/testimonial-section/TestimonialSectionOne';
import HomeFiveBlog from '../../components/home-five/HomeFiveBlog';
import FooterOne from '../../common/footer/FooterOne';
import HomeFiveInstructor from '../../components/home-five/HomeFiveInstructor';
import HomeFiveProgress from '../../components/home-five/HomeFiveProgress';
import AboutEight from '../../components/about/AboutEight';
// import HomeOneInstructor from '../../components/home-one/HomeOneInstructor';




const BlastoutSolutions = () => {
    return (
        <>
            <SEO title="BOS" />
            

            <HeaderTwo styles="header-transparent header-style-2" />

            <BannerOne />

            <HomeOneCourses/>

            <HomeFiveProgress />

            {/* <HomeOneInstructor /> */}
                      
            <VideoSectionTwo /> 

            <TestimonialSectionOne />

            <HomeTwoEvents />

            <AboutEight />

            <HomeFiveInstructor />

            <HomeFiveBlog />   

             <AboutTwo />  

            <FooterOne />   
        </>
    )
}

export default BlastoutSolutions;