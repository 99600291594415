import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';

const AboutEight = () => {
    return (
        // div className="edu-event-area eduvibe-home-two-event edu-section-gap bg-image video-gallery-overlay-area"
        <div className="eduvibe-home-five-about edu-about-area about-style-6 edu-section-gap bg-white" id='about'>
            <div className="container eduvibe-animated-shape">
                <div className="row g-5 align-items-center">
                    <ScrollAnimation 
                        animateIn="fadeIn"
                        animateOut="fadeInOut"
                        className="col-xl-5 col-lg-6"
                        animateOnce={ true }
                    >
                        <div className="about-image-gallery">
                            <img className="image-1 w-100" src="/images/about/about-08/Blastout Solutions  Cuddalore.png" alt="About Thumb" />
                            <div className="badge-icon">
                                <div className="badge-inner">
                                    <img src="/images/about/about-08/badge.png" alt="Blastout Solutions" />
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                    <div className="col-xl-6 col-lg-6 offset-xl-1">
                        <div className="inner mt_md--40 mt_sm--20">
                            <SectionTitle
                                slogan = "About Us"
                                title = "Creating A Community Of Life Long Learners"
                            />
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={ true }
                            >
                                <p className="description line-before mt--40 mb--40">Blastout E-Learning is moving with a great vision of producing technology experts by live online training classes with greatly qualified professionals as mentors. All our courses are proposed in a way with more than fifty percent of practical classes. BOS E-learning offers limited seats for each batch, that aims to povide exclusive learning experience for each participant.</p>
                            </ScrollAnimation>
                            <div className="feature-list-wrapper">
                                <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" className="feature-list" animateOnce={ true }><i className="icon-checkbox-circle-fill"></i>Online training classes</ScrollAnimation>
                                <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" className="feature-list" animateOnce={ true }><i className="icon-checkbox-circle-fill"></i>Training from Highly Qualified Professionals</ScrollAnimation>
                                <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" className="feature-list" animateOnce={ true }><i className="icon-checkbox-circle-fill"></i>Stuffed with Practical Classes</ScrollAnimation>
                                <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" className="feature-list" animateOnce={ true }><i className="icon-checkbox-circle-fill"></i>Exclusive Batches</ScrollAnimation>
                            </div>
                            <div className="read-more-btn mt--75 mt_lg--30 mt_md--40 mt_sm--40">
                                <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={ true }>
                                    <Link className="edu-btn" to="/about-us-1">Know About Us<i className="icon-arrow-right-line-right"></i></Link>
                                    <a className="info-btn" href="tel: +91 70105 37325"><i className="icon-call"></i>+91 70105 37325</a>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-05-04.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-33.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-13-05.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src="/images/shapes/shape-01-03.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-5">
                        <img src="/images/shapes/shape-06.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutEight;