import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
// import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import CourseTypeOne from '../../components/course/CourseTypeOne';
import CourseData from '../../data/course/CourseData.json';

const CourseOne = () => {
    const CourseItems = CourseData.slice(0, 6);
    return (
        <>
            <SEO title="Course Style - 1" />
            <Layout>
                {/* <BreadcrumbOne  */}
                    {/* title="Course Style - 1" */}
                    {/* rootUrl="/" */}
                    {/* parentUrl="Home" */}
                    {/* currentUrl="Course Style - 1" */}
                {/* /> */}
                <div className="edu-course-area edu-section-gap bg-color-white">
                    <h1>
                        <center> Our Featured Courses </center>
                            
                        </h1>
                    <div className="container">
                        <div className="row g-5 mt--10"> 
                            { 
                                CourseItems.map((item) => (
                                    <div className="col-12 col-sm-6 col-lg-4" key={ item.id }>
                                        <CourseTypeOne data={item} />
                                    </div>
                                ) )
                            }
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default CourseOne;